import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB OHS 12-12-12/arm`}</p>
    <p>{`then,`}</p>
    <p>{`4 rounds for time of:`}</p>
    <p>{`20-Calorie Assault Bike`}</p>
    <p>{`15-Box Jump Overs (24/20″)`}</p>
    <p>{`10/arm-DB Snatch (50/35)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The sign up sheet for Memorial Day Murph is up next to the front
white board.  We have 15 slots each half hour starting at 10:30am (10:30
& 11:00 slots are filled) on the 28th so sign up ahead of time!  If you
are a visitor and won’t be in before Murph you can email us and we’ll
sign you up.  All other class times and open gym are cancelled for the
day.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Central Regionals end today.  Check out the last day action at
Games.CrossFit.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Sunday, June 3rd we will have a Mobility for Athletic Performance
workshop from 1:45-3:00pm.  This workshop will be taught by Stephanie
Bellissimo and will focus on floor work and locomotion for CrossFit
athletes.  This intro class is free to all CrossFit the Ville members
and just \\$15 for non members.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      